import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import "./CouponBanner.css";

const CouponBanner = ({ couponStatusProp }) => {
  const [couponStatus, setCouponStatus] = useState(true);

  const couponStatusHandler = () => {
    setCouponStatus(false);
    couponStatusProp(false);
  };

  return (
    <div>
      {couponStatus && (
        <div className="coupon-wrapper">
          <div className="coupon-wrapper-text">Use code
            <span className="coupon-name"> XJTRGHHP </span>
            to save 25% off orders
          </div>
          <button onClick={couponStatusHandler} className="coupon-btn">
            <CloseIcon />
          </button>
        </div>
      )}
      {/* Add extra height for the coupon so it doesn't appear over the heroImg text */}
      <div className={`${couponStatus ? "coupon-height" : ""}`}></div>
    </div>
  );
};

export default CouponBanner;
