import PrivacyPolicyPDF from "../../../assets/mainItems/ItaliaPrivacyPolicy.pdf"

import "./PrivacyPolicy.css"

const PrivacyPolicy = (params) => {
    return <div aria-label="Privacy Policy" className="privacy-policy-wrapper">
        <span>
            Welcome and thank you for visiting.
            Italia Pizza and Pasta (“we”, “our”, or “us”) provide{" "}
            <a href={PrivacyPolicyPDF} download className="privacy-policy-pdf">This Privacy Policy</a>
            {" "}to explain our privacy and
            information practices for our direct website food ordering platform (the “services”). This Privacy Policy describes the types of personal data we collect,
            how that personal data is used and disclosed, and your rights and choices regarding your personal data.
        </span>
    </div>
}

export default PrivacyPolicy