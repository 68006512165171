import { useContext, useState, useEffect } from "react";
import WebplaceContext from "../../../store/webplace-context";

import FacebookIcon from "../../../assets/mainItems/facebookLogo.png";
import InstagramIcon from "../../../assets/mainItems/instagramLogo.png";
import TikTok from "../../../assets/mainItems/tiktokLogo.svg";
import PoweredHubster from "../../../assets/mainItems/poweredHubster.svg";
import PoweredOtter from "../../../assets/mainItems/poweredOtter.svg";
import ButtonFormat from "../../shared/ButtonFormat/ButtonFormat"
import ButtonFormatHref from "../../shared/ButtonFormatHref/ButtonFormatHref";
import WhatsApp from "../../../assets/mainItems/whatsappIcon.svg"

import "./Footer.css";

const splitString = (data) => {
  return data.split("/");
};


const Footer = () => {
  const footerLocationsCtx =
    useContext(WebplaceContext).locationInformation.locations;
  const footerInfoCtx = useContext(WebplaceContext).footerInformation;
  const contactCtx = useContext(WebplaceContext).homeSocials;
  const stylesCtx = useContext(WebplaceContext).footerInformation.footer_styles;
  const stylesSmCtx = useContext(WebplaceContext).homeSocials.home_socials_styles;

  const address = splitString(footerLocationsCtx[0].address);
  const schedule = splitString(footerLocationsCtx[0].schedule);
  const phone = footerLocationsCtx[0].phone;
  let poweredBy;

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (footerInfoCtx.powered_by_hubster === footerInfoCtx.powered_by_otter) {
    poweredBy = <></>;
  } else if (
    footerInfoCtx.powered_by_hubster &&
    !footerInfoCtx.powered_by_otter
  ) {
    poweredBy = (
      <img src={PoweredHubster} alt="Powered by" className="powered-by" />
    );
  } else {
    poweredBy = (
      <img src={PoweredOtter} alt="Powered by" className="powered-by" />
    );
  }

  return (
    <div
      className="footer-wrapper"
      style={{
        backgroundColor: stylesCtx.background_color,
        color: stylesCtx.subtitle_font_color,
      }}
      aria-label="footer"
    >
      <div className="footer-title" role="heading" aria-level="2">{footerInfoCtx.title}</div>
      <div className="footer-info-wrapper">
        {/* Locations and address */}
        {footerLocationsCtx.length === 1 && (
          <div className="info-wrapper">
            <div className="info-title" role="heading" aria-level="3">{footerInfoCtx.address_subtitle}</div>
            {address.map((data, index) => {
              return (
                <div
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                  key={index}
                >
                  {data}
                </div>
              );
            })}
          </div>
        )}
        {footerLocationsCtx.length > 1 && (
          <div className="info-wrapper info-locations-footer">
            <div className="info-title" role="heading" aria-level="3">{footerInfoCtx.locations_subtitle}</div>
            {footerLocationsCtx.map((data, index) => {
              return (
                <div
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                  key={index}
                >
                  {data.location_title}, {data.city}
                </div>
              );
            })}
          </div>
        )}

        {/* Schedule */}
        {footerLocationsCtx.length === 1 && (
          <div>
            <div className="info-title" role="heading" aria-level="3">{footerInfoCtx.hours_subtitle}</div>
            {schedule.map((data, index) => {
              return (
                <div
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                  key={index}
                >
                  {data}
                </div>
              );
            })}
          </div>
        )}

        {/* Contact information */}
        {footerInfoCtx.show_contact_option && (
          <div>
            <div className="info-title" role="heading" aria-level="3">
              {footerInfoCtx.contact_info_subtitle}
            </div>
            {footerLocationsCtx.length === 1 && (
              <div>
                <a
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                  href={`tel: ${phone}`}
                >
                  <div
                    className="info-data"
                    style={{ color: stylesCtx.info_font_color }}
                  >
                    {phone}
                  </div>
                </a>
                <a
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                  href={`emailto: Italiapizzaandpasta@yahoo.com`}
                >
                  <div
                    className="info-data"
                    style={{ color: stylesCtx.info_font_color }}
                  >
                    Italiapizzaandpasta@yahoo.com
                  </div>
                </a>
                <a
                  className="info-data"
                  style={{ color: stylesCtx.info_font_color }}
                  href={`emailto: info@italiapizzzaandpastatn.com`}
                >
                  <div
                    className="info-data"
                    style={{ color: stylesCtx.info_font_color }}
                  >
                    info@italiapizzzaandpastatn.com
                  </div>
                </a>

              </div>
            )}

          </div>
        )}

        {/* Social media & powered by */}
        <div className="socials-powered-wrapper">
          <div className="social-media-wrapper">

            <div className="social-media-inner-wrapper">
              <a
                href={contactCtx.instagram_url}
                target="_self"
                rel="noopener noreferrer"
                className="anchor-social"
                aria-label="Visit our Instagram page"
              >
                <img
                  src={WhatsApp}
                  alt="Instagram"
                  className="anchor-social-img"
                />
              </a>
              <ButtonFormatHref
                btnSize={stylesSmCtx.home_instagram_button_style.btn_size}
                showArrow={stylesSmCtx.home_instagram_button_style.show_arrow}
                arrowAtStart={stylesSmCtx.home_instagram_button_style.arrow_at_start}
                arrowColor={stylesSmCtx.home_instagram_button_style.arrow_color}
                showBorder={stylesSmCtx.home_instagram_button_style.show_border}
                borderColor={stylesSmCtx.home_instagram_button_style.border_color}
                backgroundColor={
                  stylesSmCtx.home_instagram_button_style.background_color
                }
                fontColor={stylesSmCtx.home_instagram_button_style.font_color}
                capitalizeText={stylesSmCtx.home_instagram_button_style.capitalize_text}
                btnText={"Chat With Us"}
                url={"https://wa.me/+16157561822"}
                fontSizeChange={windowSize > 778 && windowSize <= 974 ? "7px" : windowSize >= 1140 && windowSize < 1281 ? "10px" : "15px"}
              />
            </div>

            {contactCtx.instagram_url.length > 0 && (
              <div className="social-media-inner-wrapper">
                <a
                  href={contactCtx.instagram_url}
                  target="_self"
                  rel="noopener noreferrer"
                  className="anchor-social"
                  aria-label="Visit our Instagram page"
                >
                  <img
                    src={InstagramIcon}
                    alt="Instagram"
                    className="anchor-social-img"
                  />
                </a>
                <ButtonFormat
                  btnSize={stylesSmCtx.home_instagram_button_style.btn_size}
                  showArrow={stylesSmCtx.home_instagram_button_style.show_arrow}
                  arrowAtStart={stylesSmCtx.home_instagram_button_style.arrow_at_start}
                  arrowColor={stylesSmCtx.home_instagram_button_style.arrow_color}
                  showBorder={stylesSmCtx.home_instagram_button_style.show_border}
                  borderColor={stylesSmCtx.home_instagram_button_style.border_color}
                  backgroundColor={
                    stylesSmCtx.home_instagram_button_style.background_color
                  }
                  fontColor={stylesSmCtx.home_instagram_button_style.font_color}
                  capitalizeText={stylesSmCtx.home_instagram_button_style.capitalize_text}
                  btnText={"Follow us"}
                  url={contactCtx.instagram_url}
                  fontSizeChange={windowSize > 778 && windowSize <= 974 ? "7px" : windowSize >= 1140 && windowSize < 1281 ? "10px" : "15px"}
                />
              </div>
            )}
            {contactCtx.facebook_url.length > 0 && (
              <div className="social-media-inner-wrapper">
                <a
                  href={contactCtx.facebook_url}
                  target="_self"
                  rel="noopener noreferrer"
                  className="anchor-social"
                  aria-label="Visit our Facebook page"
                >
                  <img
                    src={FacebookIcon}
                    alt="Facebook"
                    className="anchor-social-img"
                  />
                </a>
                <ButtonFormat
                  btnSize={stylesSmCtx.home_instagram_button_style.btn_size}
                  showArrow={stylesSmCtx.home_instagram_button_style.show_arrow}
                  arrowAtStart={stylesSmCtx.home_instagram_button_style.arrow_at_start}
                  arrowColor={stylesSmCtx.home_instagram_button_style.arrow_color}
                  showBorder={stylesSmCtx.home_instagram_button_style.show_border}
                  borderColor={stylesSmCtx.home_instagram_button_style.border_color}
                  backgroundColor={
                    stylesSmCtx.home_instagram_button_style.background_color
                  }
                  fontColor={stylesSmCtx.home_instagram_button_style.font_color}
                  capitalizeText={stylesSmCtx.home_instagram_button_style.capitalize_text}
                  btnText={"Follow us"}
                  url={contactCtx.facebook_url}
                  fontSizeChange={windowSize > 778 && windowSize <= 974 ? "7px" : windowSize >= 1140 && windowSize < 1281 ? "10px" : "15px"}
                />
              </div>
            )}
            {contactCtx.tiktok_url.length > 0 && (
              <div className="social-media-inner-wrapper">
                <a
                  href={contactCtx.tiktok_url}
                  target="_self"
                  rel="noopener noreferrer"
                  className="anchor-social"
                  aria-label="Visit our TikTok page"
                >
                  <img
                    src={TikTok}
                    alt="TikTok"
                    className="anchor-social-img"
                  />
                </a>
                <ButtonFormat
                  btnSize={stylesSmCtx.home_instagram_button_style.btn_size}
                  showArrow={stylesSmCtx.home_instagram_button_style.show_arrow}
                  arrowAtStart={stylesSmCtx.home_instagram_button_style.arrow_at_start}
                  arrowColor={stylesSmCtx.home_instagram_button_style.arrow_color}
                  showBorder={stylesSmCtx.home_instagram_button_style.show_border}
                  borderColor={stylesSmCtx.home_instagram_button_style.border_color}
                  backgroundColor={
                    stylesSmCtx.home_instagram_button_style.background_color
                  }
                  fontColor={stylesSmCtx.home_instagram_button_style.font_color}
                  capitalizeText={stylesSmCtx.home_instagram_button_style.capitalize_text}
                  btnText={"Follow us"}
                  url={contactCtx.tiktok_url}
                  fontSizeChange={windowSize > 778 && windowSize <= 974 ? "7px" : windowSize >= 1140 && windowSize < 1281 ? "10px" : "15px"}
                />
              </div>
            )}
          </div>
          <div className="powered-by-wrapper">
            {poweredBy}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
